import React from "react"
import Service from "../../components/Shared/Service"
import checkMark from "../../assets/icons/noun-check-mark.svg"
import migrateToCloud from "../../assets/icons/migrateToCloud.svg"
import cloudMigration1 from "../../assets/icons/noun_Money making_2324432.svg"
import cloudroadmap from "../../assets/icons/noun_Cloud_808241.svg"
import { graphql } from "gatsby"

const seoTitle = "Cloud Solutions"
const seoDescription =
  " Atyeti modernizes infrastructure – architecting and deploying workloads across public clouds, data centers, and edge locations based on requirements specific to your digital business."
const keyword =
  " Cloud, Database Migration, Application Modernization, DevOps, Infrastructure Automation, Analytics, Cost optimization- FinOps, Cloud Security"
const mainHeading = "Cloud Solutions"
const mainPara = `We modernize infrastructure – architecting and deploying workloads across public clouds, data centers, and edge locations based on requirements specific to your digital business.`

const serviceIcons = [
  { id: 1, name: "Database Migration", icon: checkMark },
  { id: 2, name: " Application Modernization", icon: checkMark },
  { id: 3, name: "DevOps", icon: checkMark },
  { id: 4, name: "Infrastructure Automation", icon: checkMark },
  { id: 5, name: "Analytics", icon: checkMark },
  { id: 6, name: "AI/ ML", icon: checkMark },
  { id: 7, name: " Cost optimization- FinOps", icon: checkMark },
  { id: 8, name: "Cloud Security", icon: checkMark },
]

const section2Heading = `Migrating To The Cloud`
const section2Para = `Are you looking to migrate out of your data center or to modernize your applications but have too many questions keeping you back? Take a look at our roadmap aimed at giving you the confidence to modernize.`

const cardItems = [
  {
    id: 1,
    icon: cloudroadmap,
    heading: ` Cloud Roadmap`,
    listItems: (
      <>
        <li>Defined frameworks for a successful cloud strategy.</li>
        <li>Best Practices.</li>
        <li>Application Modernization- RePlatforming, Microservices</li>
        <li>Manage your data in the cloud.</li>
        <li>Cloud Optimization- Fin Ops.</li>
        <li>DevOps Transformation</li>
      </>
    ),
  },
  {
    id: 2,
    icon: migrateToCloud,
    heading: `Cloud Automation`,
    listItems: (
      <>
        <li>
          IaC- Implement solutions like HashiCorp Terraform or configuration
          languages like Ansible.
        </li>
        <li>
          Automate the process of managing and provisioning infrastructure
          through code. This makes it easier to edit and distribute
          configurations.
        </li>
        <li>
          PaaS - Atyeti’s teams can help you build a Platform as a service
          (PaaS).{" "}
        </li>
        <li>
          This can offer users a complete development and deployment environment
          in the cloud, with resources that enable you to deliver cloud-enabled
          applications.
        </li>
      </>
    ),
  },
  {
    id: 3,
    icon: cloudMigration1,
    heading: `Cloud Migration`,
    listItems: (
      <>
        <li>Define specific approach for each application.</li>
        <li>Cost Management.</li>
        <li>Data Security and Compliance.</li>
        <li>‘Lift and shift,’ using infrastructure-as-a-service (IaaS).</li>
        <li>Refactor, Rebuild or Replace Code.</li>
      </>
    ),
  },
]

const ServiceDetails = ({ data }) => (
  <Service
    seoTitle={seoTitle}
    mainHeading={mainHeading}
    mainPara={mainPara}
    serviceIcons={serviceIcons}
    // section2Heading={section2Heading}
    cardItems={cardItems}
    section2Para={section2Para}
    caseItems={data}
    seoDescription={seoDescription}
    keyword={keyword}
    clodSolution
    className="cloud"

  />
)
export const query = graphql`
  query CloudCaseQuery {
    casestudyJson(mainheading: { eq: "cloud-solutions" }) {
      data {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        para
        slug
        subheading
      }
      bannerimage {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
export default ServiceDetails
